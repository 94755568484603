<template>
<div class="community-market-create">
    <div class="notice vertical-center">
  <img src="~assets/images/index/notice.png" class="notice-img"/>
  <span>发布须知：跳蚤市场为实物邮寄专区，发错板块将被下架！</span>
  </div>
  <van-field v-model="title" maxlength='40' placeholder="请输入商品标题" border="{{ false }}"  class="title" />
  <van-divider class="line" />
  <van-field v-model="content" rows="5"  maxlength='2000' show-word-limit placeholder="请输入正文内容..." type="textarea" border="{{ false }}" class="content" />
  <van-uploader v-model="fileList" :after-read="afterRead"  max-count="5"  :before-delete="deleteImg" />
  <van-cell title="商品分类" is-link :value="category_value" @click="changeCategory()" title-class="cell-l"/>
  <van-divider class="line" />
  <van-cell title="商品积分" title-class="cell-l">
    <input type="number" v-model="credit"  onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" maxlength='40' placeholder="请输入商品积分" border="{{ false }}"  class="create-input" />
  </van-cell>
  <van-divider class="line" />
  <van-cell title="商品库存" title-class="cell-l">
    <van-stepper v-model="stock" min="1"  max="999" integer />
  </van-cell>
  <van-divider class="line" />
  <van-radio-group v-model="is_free_shipping"  class="vertical-center cell-r" title-class="cell-l">
    <van-cell-group>
      <van-cell title="是否包邮" class="vertical-center" style="width: 100vw;" title-class="cell-l">
        <van-radio  name="1" class="vertical-center"  style="margin-right:60px;">是</van-radio>
        <van-radio  name="0" class="vertical-center">否</van-radio>
      </van-cell>
    </van-cell-group>
  </van-radio-group>
  <van-divider class="line" />
  <van-cell title="运费积分"  v-if="is_free_shipping == '0'"  title-class="cell-l">
    <input type='number' v-model="postage" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" maxlength='40' placeholder="请输入运费积分" border="{{ false }}" class="create-input" />
  </van-cell>
  <div class="btmbox"></div>
  <van-action-sheet v-model:show="categoryShow" :actions="category" cancel-text="取消" description="选择悬赏分类" close-on-click-action @cancel="onClose"
  @select="categorySelect" />
  <van-action-sheet v-model:show="integralShow" :actions="creditList" cancel-text="取消" description="选择悬赏积分" close-on-click-action @cancel="onClose"
  @select="integralSelect" />
  <van-action-sheet v-model:show="freightShow" :actions="freightList" cancel-text="取消" description="选择悬赏积分" close-on-click-action @cancel="onClose"
  @select="freightSelect" />
  <div class="foot">
    <div class="footer vertical-center">
      <div class="footer-l vertical-center"  @click="sub('0')">
        <img class="footer-l-img"  src="~assets/images/index/draft.png" />
        存草稿
      </div>
      <div class="footer-r vertical-center"  @click="sub('1')">
        发布
      </div>
    </div>
    <div class="safety-height"></div>
  </div>
  <van-toast id="van-toast" />
</div>
</template>

<script>
import UserInfo from "components/userInfo";
import _ from 'lodash'
export default {
  name: "community.create",
  data(){
    return{
          fileList: [],
        show: false,
        title:'',
        content:'',
        category:[],
        category_id:'',
        categoryShow:false,
        integralShow:false,
        category_value:'请选择分类',
        credit:'',
        credit_value:'请选择积分',
        is_free_shipping:'1',
        shipping_show:false,
        freightShow:false,
        postage_value:'请选择积分',
        postage:'',
        fileUrl:[],
        osscdn:'',
        stock:1,
        subbtn:true,
        creditList:[
          {
            name:5
          },
          {
            name:10
          },
          {
            name:15
          },
          {
            name:20
          },
        ],
        freightList:[
          {
            name:5
          },
          {
            name:10
          },
          {
            name:15
          },
          {
            name:20
          },
        ],
    actions: [
      {
        name: '选项',
      },
      {
        name: '选项',
      },
      {
        name: '选项',
      },
    ],
    radio: '1',
    }
  },

    created() {
        if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.info()
      }
      this.CommunityCategory()
          
  },
  methods:{
     //详情
    info(){
      let url = this.$api.communityFlea +'/' + this.$api.edit   + '/' + this.id 
      this.$http.get(url, true).then(res => {
        if(res.data.success){
          let list = []
            for ( let i of res.data.data.picture){
              let item = {}
              item.type = 'image',
              item.url = i
              list.push(item)
            }
            this.category_id = res.data.data.category_id,
            this.credit = res.data.data.credit,
              this.title = res.data.data.title,
              this.content = res.data.data.content,
              this.status = res.data.data.status,
              this.fileUrl = res.data.data.picture,
              this.postage = res.data.data.postage,
              this.is_free_shipping = res.data.data.is_free_shipping.toString(),
              this.credit_value = res.data.data.credit,
              this.postage_value = res.data.data.postage,
              this.fileList = list,
              this.stock = res.data.data.stock,
              this.category_value = res.data.data.community_categories.name
            if(res.data.data.is_free_shipping == 0){
              this.shipping_show = true
            }
        }
      })
    },
    //提交
    sub: _.debounce(function(status){
      if(!this.subbtn){
        return
      }
      if(this.category_id == ''){
        this.$toast('请选择商品分类');
        return
      }
      if(this.title == ''){
        this.$toast('请输入商品标题');
        return
      }
      if(this.title.length < 4){
        this.$toast('标题为4-40字');
        return
      }
      if (status == 1){

        if(this.credit == '' && this.credit != 0){
          this.$toast('请输入商品积分');
          return
        }
        if(this.stock == '' && this.stock == 0){
          this.$toast('库存不能为0');
          return
        }
        if( this.credit <= 0 || isNaN(this.credit) || this.credit >= 1000){
          this.$toast('商品积分为数字且大于0小于1000');
          return
        }

        if(this.fileUrl.length < 1){
          this.$toast('至少上传一张图片');
          return
        }
        if(this.stock >= 1000){
          this.$toast('库存不能超过999');
          return
        }

        if(this.is_free_shipping == 0){
          if(this.postage == '' ){
            this.$toast('请输入运费积分');
            return
          }
          if( this.postage <= 0 || isNaN(this.postage) || this.postage >= 1000){
            this.$toast('运费积分为数字且大于0小于1000');
            return
          }
        }
      }

      if(this.id){
        if( this.status == 1 &&  status == 0){
          this.$toast('已发布不可再存草稿');
          return
        }
        let url = this.$api.communityFlea 
        let data = {
        category_id:this.category_id,
        credit:Number(this.credit),
        title:this.title,
        content:this.content,
        status:status,
        picture:this.fileUrl,
        postage:this.postage,
        is_free_shipping:this.is_free_shipping,
        stock:Number(this.stock),
        id:this.id
      }
      this.$http.put(url,data, true).then(res => {
        if(res.data.success){
          this.subbtn = false
          this.$toast('修改成功');
          this.$store.commit('M_ACT','1')
          if(status == 0){
            this.$store.commit('M_ACTS','1')
          }else{
            this.$store.commit('M_ACTS','0')
          }
            
              this.$router.go(-1)
        }else{
            this.$toast(res.data.message);
          }
      }).catch(error =>{
      if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
    })
      }else{
        let url = this.$api.communityFlea 
        let data = {
          category_id:this.category_id,
          credit:Number(this.credit),
          title:this.title,
          content:this.content,
          status:status,
          picture:this.fileUrl,
          postage:this.postage,
          is_free_shipping:this.is_free_shipping,
          stock:Number(this.stock)
        }
        this.$http.post(url,data, true).then(res => {
          if(res.data.success){
             this.subbtn = false
                   if(status == 0){
            this.$toast('保存成功');
          }else{
            this.$toast('发布成功');
          }
            this.$store.commit('M_ACT','1')
           this.$router.go(-1)
          }else{
            this.$toast(res.data.message);
          }
        }).catch(error =>{
      if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
    })
      }
     
    },500),
    //社区分类
    CommunityCategory(){
      let url = this.$api.CommunityCategory + '?type=flea'
       this.$http.get(url, true).then(res => {
        if(res.data.success){
            this.category=res.data.data
        }
      })
    },





    //分类
    changeCategory(){
      this.categoryShow = true
  },

  onClose() {
    this.categoryShow = false
      this.integralShow = false 
      this.freightShow = false
},

    afterRead(event) {
  let data =new FormData()
      data.append('file', event.file)
      data.append('type', 'community')
      this.$http.post(this.$api.imageUpload, data, true).then(res => {
          if(res.data.success){
              this.fileUrl= this.fileUrl.concat(res.data.data.url)
          } else {
            //   wx.showToast({
            //       title: '上传失败',
            //       icon: 'error',
            //       duration: 2000
            //   })
          }
      })
      },
 //      //  删除图片
    deleteImg(event) {
        let list = this.fileList
        let url = this.fileUrl
        list.splice(event.index,1)
        url.splice(event.index,1)
        this.fileList=list
          this.fileUrl=url
    },
   
    categorySelect(event) {
      this.category_id=event.id,
        this.category_value=event.name
    },
    integralSelect(event) {
      this.credit=event.name,
        this.credit_value=event.name
    },
    freightSelect(event) {
      this.postage=event.name,
        this.postage_value=event.name
    },
  },
  components:{
    UserInfo,
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/community/market-create.scss";
</style>
<style lang="scss">
$r: 750/16/1rem;
.van-cell:after{
  border: unset;
}
.cell-l{
  width: 80px;
  flex: none;
}
.van-cell__value{
  text-align: left;
}
.cell-r .van-cell__value{
  text-align: left;
  display: flex;
}
input{
    border: none;
}
.van-radio__icon--checked .van-icon{
  background: #FF6B29;
  border:#FF6B29 ;
}
   .van-uploader {
      margin: 0 50/$r;
    }
  
    .van-uploader__preview {
      width: 200/$r;
      height: 200/$r;
    }
  
    .van-uploader__preview-image, .van-uploader__upload {
      width: 200/$r!important;
      height: 200/$r!important;
    }
</style>